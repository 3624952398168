<template>
  <el-dialog
      title="修改支出"
      :visible.sync="show"
      width="50%"
      :append-to-body="append"
      center>
    <el-row>
      <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px" :model="spendForm" :rules="rules" ref="form">
        <el-col>
          <el-form-item label="报销人" prop="spendPeople">
            <el-input v-model="spendForm.spendPeople" placeholder="报销人姓名" clearable style="width: 28%"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="支出日期" prop="createDate">
            <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="spendForm.createDate"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="支出项目" prop="spendProjectsId">
            <el-select v-model="spendForm.spendProjectsId" placeholder="支出项目" clearable filterable>
              <el-option
                  v-for="item in projectsArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="店铺" prop="shopId">
            <el-select v-model="spendForm.shopId" placeholder="所属店铺" clearable>
              <el-option
                  v-for="item in shops"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支出金额" prop="spendAmount">
            <el-input v-model="spendForm.spendAmount" placeholder="支出金额" clearable style="width: 28%"></el-input>
          </el-form-item>
          <el-form-item label="支出内容" prop="content">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容"
                v-model="spendForm.content">
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-edit",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    },
    spend: {
      type: Object,
      required: true
    },
    append: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  created() {
    /* 搜索支出项目 */
    this.$selectUtils.queryProjectsByType(this.$projectsType.category).then(response => {
      this.projectsArray = JSON.parse(response.data.data)
    })
    /* 搜索店铺 */
    this.$selectUtils.shopIdsIsValid().then(response => {
      this.shops = JSON.parse(response.data.data)
    })
  },
  data() {
    return {
      spendForm: {
        id: this.spend.id,
        spendPeople: this.spend.spendPeople,
        createDate: this.spend.createDate,
        spendProjectsId: this.spend.spendProjectsId,
        shopId: this.spend.shopId,
        spendAmount: this.spend.spendAmount,
        content: this.spend.content,
      },
      projectsArray: [],
      shops: [],
      rules: {
        spendPeople: [{required: true, message: '请输入报销人', trigger: 'blur'}],
        createDate: [{required: true, message: '请选择支出日期', trigger: 'blur'}],
        spendProjectsId: [{required: true, message: '请选择支出项目', trigger: 'blur'}],
        shopId: [{required: true, message: '请选择店铺', trigger: 'blur'}],
        spendAmount: [{required: true, message: '请输入支出金额', trigger: 'blur'}],
        content: [{required: true, message: '请输入支出内容', trigger: 'blur'}],
      },
    }
  },
  methods: {
    close() {
      this.show = false
    },
    submit() {
      this.$axios({
        method: 'POST',
        url: '/spend/editSpend',
        params: {
          ...this.spendForm
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('修改成功')
          this.close()
          this.$emit('close')
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
  }
}
</script>

<style scoped>

</style>