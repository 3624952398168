<template>
  <div>
    <el-row style="margin-left: 10%;margin-top: 1%;size: B4">
      <p style="font-weight: bold;font-size: 25px;margin-left: 40%">申报填写</p>
      <el-col :span="11" style="margin-top: 5%">
        <el-form label-width="150px" style="margin-top: 10px;margin-left: 15px" :model="declareForm" :rules="rules" ref="form">
          <el-row>
            <el-col :span="11">
              <el-form-item label="报销收款方式" prop="spendPeople">
                <el-select v-model="declareForm.spendPeople" placeholder="报销收款方式" clearable>
                  <el-option
                      v-for="item in SpendPeopleArray"
                      :key="item.id"
                      :label="item.spendPeopleAccount + '-' + item.spendPeopleRemark"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="申报日期" prop="declarationDate">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="declareForm.declarationDate"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="申报描述" prop="content">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="declareForm.content">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="spendAdd('form')">创建申报</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6" style="margin-top: 5%">
        <el-divider content-position="center">温馨提示</el-divider>
        <p v-if="SpendPeopleArray==''" style="font-weight: bold;font-size: 20px;margin-left: 10%">您还没有对应的收款方式，请前往报销收款参数列表新增</p>
        <br/>
        <p v-if="SpendDeclareArray==''" style="font-weight: bold;font-size: 20px;margin-left: 10%">您目前还没有添加过申报</p>
      </el-col>
    </el-row>
    <el-row>
      <legend>我的申报</legend>
      <el-table
          :data="SpendDeclareArray"
          border
          v-loading="loading"
          size="medium"
          element-loading-text="拼命加载中"
          max-height="630"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          style="width: 99%">
        <el-table-column prop="declareName" label="申报人" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="declarationDate" label="申报日期" align="center" width="120" sortable fixed></el-table-column>
        <el-table-column prop="spendMethodName" width="120" align="center" label="收款方式"></el-table-column>
        <el-table-column prop="spendAmount" width="125" align="center" label="收款总金额"></el-table-column>
        <el-table-column width="100" align="center" label="申报状态">
          <template slot-scope="scope">
            <span>
              {{scope.row.state === 0 ? "新建未提交" :scope.row.state === 1 ? "提交待审核" :scope.row.state === 2 ? "已审核待结算" :scope.row.state === 3 ? "已结算" :scope.row.state === 4 ? "已退回" : "申报已完成"}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewerName" width="120" align="center" label="审核人"></el-table-column>
        <el-table-column prop="clearingPersonName" width="120" align="center" label="结算人"></el-table-column>
        <el-table-column prop="spendTotal" width="120" align="center" label="支出总数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" width="220" align="center" label="申报描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="remark" width="220" align="center" label="退回批注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="expendAddOpen(scope.row)" v-if="scope.row.state === 0">添加支出</el-button>
              <el-button type="success" size="mini" @click="submitSpendDeclare(scope.row)" v-if="scope.row.state === 0">提交</el-button>
              <el-button type="warning" size="mini" @click="returnSpendDeclare(scope.row)" v-if="scope.row.state === 1">撤回</el-button>
              <el-button type="danger" size="mini" @click="removeSpendDeclare(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <spendAdd :declare="declare" :expendAddState="expendAddState" v-if="expendAddState" @close="expendAddClose"></spendAdd>
  </div>
</template>
<script>
import spendAdd from "@/pages/spend/spend/spend-add"

export default {
  name: "declare-add",
  created() {
    this.pageInit()
  },
  components: {
    spendAdd,
  },
  data() {
    return {
      declare: {},
      loading: true,
      expendAddState:false,
      fileList: [],
      projectsArray: [],
      SpendPeopleArray: [],
      shops: [],
      SpendDeclareArray:[],
      dialogImageUrl: "",
      empName: JSON.parse(localStorage.getItem("emp")).empName,
      empId: JSON.parse(localStorage.getItem("emp")).id,
      declareName:"",
      declareForm: {
        declareId: JSON.parse(localStorage.getItem("emp")).id,
        spendPeople:"",
        declarationDate:new Date(),
        content:"",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        spendPeople: [{required: true, message: '请选择收款方式', trigger: 'blur'}],
        declarationDate: [{required: true, message: '请选择申报日期', trigger: 'blur'}],
        declareRule: [{required: true, message: '请输入申报人', trigger: 'blur'}],
        content: [{required: true, message: '请输入申报描述', trigger: 'blur'}],
      },
    }
  },
  methods: {
    /* 查询收款方式 */
    querySpendPeopleList() {
      this.$axios({
        method: "GET",
        url: "/spendPeople/querySpendPeopleList",
        params: {
          spendPeople: JSON.parse(localStorage.getItem("emp")).id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.SpendPeopleArray = response.data.data.list;
      })
    },
    //查询我的申报列表
    querySpendDeclareList() {
      this.$axios({
        method: "GET",
        url: "/spendDeclare/spendDeclareList",
        params: {
          declareId: JSON.parse(localStorage.getItem("emp")).id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.loading = false
        this.SpendDeclareArray = response.data.data.list;
      })
    },
    //删除申报
    removeSpendDeclare(val) {
      if (val.state === 0 || val.state === 4) {
        this.$axios({
          method: "POST",
          url: "/spendDeclare/removeSpendDeclare",
          params: {
            id: val.id,
            tenantCrop: localStorage.getItem("tenantCrop"),
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '删除申报成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
        })
      } else {
        this.$message({
          showClose: true,
          message: "只有未提交的申报才可以删除操作",
          type: 'error',
          duration: 1000,
        })
      }
      this.pageInit()
    },
    submitSpendDeclare(val) {
      if (val.state === 0 || val.state === 4) {
        this.$axios({
          method: "POST",
          url: "/spendDeclare/submitSpendDeclare",
          params: {
            id: val.id,
            tenantCrop: localStorage.getItem("tenantCrop"),
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '提交申报成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.querySpendDeclareList();
        })
      } else {
        this.$message({
          showClose: true,
          message: "只有未提交的申报才可以进行提交操作",
          type: 'error',
          duration: 1000,
        })
      }
      this.pageInit()
    },
    //创建申报
    spendAdd(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$alert("确认新增此条申报吗？",
              "新增申报",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
              }).then(() => {
            this.$axios({
              method: "POST",
              url: "/spendDeclare/addSpendDeclare",
              params: {
                ...this.declareForm,
                settlement: 0,
              },
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '申报创建成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.pageInit()
              this.reset('form')
            })
          }).catch(() => {
          })
        } else {
          return false;
        }
      });
    },
    //重置
    reset(form) {
      this.$refs[form].resetFields();
    },
    //开启添加支出界面
    expendAddOpen(val) {
      if (val.state === 0 || val.state === 4) {
        this.declare = val;
        this.expendAddState = true;
      }else {
        this.$message({
          showClose: true,
          message: "只有未提交或已退回的申报才可以进行添加支出操作",
          type: 'error',
          duration: 3000,
        })
      }
    },
    //关闭添加支出界面
    expendAddClose() {
      this.expendAddState = false
      this.querySpendDeclareList()
    },
    pageInit() {
      /* 搜索收款参数 */
      this.querySpendPeopleList();
      /* 搜索申报列表 */
      this.querySpendDeclareList();
    },
    // 撤回申报
    returnSpendDeclare(row) {
      this.$axios({
        method: "POST",
        url: "/spendDeclare/updateSpendDeclare",
        params: {
          id: row.id,
          state: 0
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('撤回成功')
          this.querySpendDeclareList()
        } else {
          this.$message.error(response.data.msg)
        }
      })
    }
  },
}
</script>

<style scoped>
</style>