<template>
  <div>
    <el-dialog
        title="添加支出"
        width="65%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-row style="margin-left: 10%;margin-top: 1%;size: B4">
        <el-col :span="16">
          <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px" :model="form" :rules="rules" ref="form">
            <el-row>
              <el-col :span="11">
                <el-form-item label="店铺" prop="shopId">
                  <el-select v-model="form.shopId" placeholder="请选择店铺" clearable>
                    <el-option
                        v-for="item in shops"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="支出金额" prop="spendAmount">
                  <el-input v-model="form.spendAmount" placeholder="请输入申报金额" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="支出项目" prop="spendProjectsId">
                  <el-select v-model="form.spendProjectsId" placeholder="支出项目" clearable filterable>
                    <el-option
                        v-for="item in projectsArray"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="支出时间" prop="declarationDate">
                  <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="form.declarationDate"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="报销人" style="font-size: 20px" prop="spendPeople">
                  <el-input v-model="form.spendPeople" placeholder="请输入报销人姓名" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" style="margin-left: 7%">
                <el-button type="success" @click="setEmpName()">如果报销人是本人请点击</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="22">
                <el-form-item label="支出内容" prop="content">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 4}"
                      placeholder="请输入内容"
                      v-model="form.content">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button type="primary" @click="spendAdd('form')">添加支出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-divider content-position="center">上传支出图片</el-divider>
          <el-upload
              style="margin-left: 30%;"
              class="avatar-uploader"
              list-type="picture-card"
              ref="upload"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleAvatarChangeIcon">
            <img v-if="dialogImageUrl" :src="dialogImageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="submitSpendDeclare()" size="medium">提 交</el-button>
      </span>
      <el-row>
        <legend>此条申报所添加的支出</legend>
        <el-table
            :data="SpendArray"
            border
            v-loading="loading"
            size="medium"
            element-loading-text="拼命加载中"
            max-height="630"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :default-sort = "{prop: 'createDate', order: 'descending'}"
            style="width: 99%">
          <el-table-column prop="declarationDate" label="支出日期" align="center" width="170" sortable fixed></el-table-column>
          <el-table-column prop="spendProjectsName" width="150" align="center" label="支出项目"></el-table-column>
          <el-table-column prop="shopName" width="125" align="center" label="店铺"></el-table-column>
          <el-table-column prop="spendAmount" width="125" align="center" label="支出金额"></el-table-column>
          <el-table-column width="125" align="center" label="状态">
            <template slot-scope="scope">
            <span>
              {{scope.row.settlement === 0 ? "预支" : "结算"}}
            </span>
            </template>
          </el-table-column>
          <el-table-column prop="content" width="300" align="center" label="支出描述" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width="300" align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="success" size="mini" @click="editSpendImage(scope.row.id)" style="margin-right: 20px">支出图片</el-button>
                <el-button type="primary" size="mini" @click="editSpend(scope.row)" style="margin-right: 20px">编辑</el-button>
                <el-button type="danger" size="mini" @click="removeSpend(scope.row)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <spend-edit :visible.sync="editVisible" :spend="spend" :append="true" v-if="editVisible" @close="querySpendList"></spend-edit>
    </el-dialog>
  </div>
</template>

<script>
import SpendEdit from "@/pages/spend/spend/spend-edit";
export default {
  name: "spend-add",
  components: {SpendEdit},
  created() {
    this.pageInit()
  },
  props: {
    expendAddState: {
      type: Boolean,
      default: false,
      required: true,
    },
    declare: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: true,
      editVisible: false,
      fileList: [],
      projectsArray: [],
      SpendArray: [],
      shops: [],
      dialogImageUrl: "",
      spendPeople: "",
      spend: {},
      empName: JSON.parse(localStorage.getItem("emp")).empName,
      form: {
        spendAmount: "",
        spendProjectsId: "",
        spendPeople: '',
        shopId: "",
        content: "",
        spendImage: "",
        declareId: this.declare.id,
        declarationDate: new Date(),
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      state: this.expendAddState,
      declareForm: {
        ...this.declare
      },
      rules: {
        spendPeople: [{required: true, message: '请输入报销人', trigger: 'blur'}],
        spendMethod: [{required: true, message: '请选择收款方式', trigger: 'blur'}],
        spendAmount: [{required: true, message: '请输入支出金额', trigger: 'blur'}],
        spendProjectsId: [{required: true, message: '请选择支出项目', trigger: 'blur'}],
        shopId: [{required: true, message: '请选择店铺', trigger: 'blur'}],
        declarationDate: [{required: true, message: '请选择支出时间', trigger: 'blur'}],
        content: [{required: true, message: '请输入申报内容', trigger: 'blur'}]
      },
    }
  },
  methods: {
    /* 设置当前登陆人为申报人 */
    setEmpName(){
      this.form.spendPeople = this.empName;
      this.spendPeople = this.empName;
    },
    //查询支出列表 根据申报id
    querySpendList() {
      this.$axios({
        method: "GET",
        url: "/spend/querySpendList",
        params: {
          declareId: this.declare.id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.loading = false
        this.SpendArray = response.data.data.list;
      })
    },
    //移除支出
    removeSpend(val) {
      this.$axios({
        method: "POST",
        url: "/spend/removeSpend",
        params: {
          id:val.id,
          declareId: val.declareId,
          spendAmount: val.spendAmount,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '删除支出成功' : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1000,
        });
        this.querySpendList()
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //添加支出
    spendAdd(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$alert("请确认添加支出",
              "添加支出",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
              }).then(() => {
            this.$axios({
              method: "POST",
              url: "/spend/addSpend",
              data: {
                ...this.form,
                settlement: 0,
              },
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '添加支出成功' : response.data.msg,
                type: flag ? 'success' : 'error',
                duration: 1000,
              });
              this.pageInit()
              this.reset('form')
              this.dialogImageUrl = ''
            })
          }).catch(() => {
          })
        } else {
          return false;
        }
      });
    },
    pageInit() {
      /* 搜索支出项目 */
      this.$selectUtils.queryProjectsByType(this.$projectsType.category).then(response => {
        this.projectsArray = JSON.parse(response.data.data)
      })
      /* 搜索店铺 */
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shops = JSON.parse(response.data.data)
      })
      /* 搜索支出列表 */
      this.querySpendList();
      this.$selectUtils.queryStyleImageTypes(0).then(response => {
        this.styleImageArray = JSON.parse(response.data.data)
      })
    },
    //提交申报
    submitSpendDeclare() {
      this.$axios({
        method: "POST",
        url: "/spendDeclare/submitSpendDeclare",
        params: {
          id: this.declare.id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '提交申报成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
      })
      this.pageInit()
    },
    //重置
    reset(form) {
      this.$refs[form].resetFields();
    },
    close() {
      this.$emit("close");
      this.$refs['upload'].clearFiles()
    },
    handleAvatarChangeIcon(file) {
      this.file = file
      this.dialogImageUrl = URL.createObjectURL(file.raw)
      return new Promise((resolve, reject) => {
        if (this.dialogImageUrl) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$upload.expendImageUpload(this.file.raw)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.$message({
                    showClose: true,
                    message: '上传图片成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.form.spendImage = data.data
                  loading.close()
                  resolve(true)
                } else {
                  this.$message({
                    showClose: true,
                    message: '上传图片失败',
                    type: 'error',
                    duration: 1000,
                  });
                  loading.close()
                  reject(false)
                }
              }).catch(error => {
            console.log(error)
            loading.close()
          })
        } else {
          resolve(true)
        }
      })
    },
    editSpend(item) {
      this.spend = item
      this.editVisible = true
    },
    editSpendImage(id) {

    }
  },
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>